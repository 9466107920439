<template>
  <div class="col-8 mx-auto justify-content-center">
    <h3>Dodaj Płatność</h3>
    <form class="add-form bg-light border" @submit.prevent>
      <small>Wybierz kontrahenta</small>
      <v-select
        v-model="contractor"
        label="label"
        :options="contractors"
        placeholder="Wybierz kontrahenta"
      ></v-select>
      <small>Numer faktury</small>
      <input
        v-model="invoiceNumber"
        type="text"
        class="form-control mt-1 mb-1"
        placeholder="Numer faktury"
      />
      <div style="color: red; font-weight: 700" v-if="checkIfExist.length > 0">
        Faktura o numerze
        <span style="text-decoration: underline">{{
          checkIfExist[0].invoiceNumber
        }}</span>
        istnieje
      </div>

      <small>Kwota</small>
      <input
        v-model="price"
        type="text"
        class="form-control mt-1 mb-1"
        placeholder="Kwota "
      />
      <small>Typ płatności</small>
      <v-select
        v-model="type"
        :options="['Nalezności', 'Koszty']"
        placeholder="Typ płatności"
      >
      </v-select>

      <small>Data płatności</small>
      <input
        v-model="date"
        type="date"
        class="form-control mt-1 mb-1"
        placeholder="Data płatności"
      />
      <small>Uwagi</small>
      <!-- <textarea
        v-model="notice"
        type="text"
        class="form-control mb-1"
        placeholder="Uwagi"
      /> -->
      <vue-editor v-model="notice"></vue-editor>

      <button
        style="width: 100%; height: 65px"
        class="btn btn-info btn-sm mt-1"
        @click="addPayment()"
      >
        Dodaj płatność
      </button>
    </form>
  </div>
</template>
<script>
import router from "../router/index";
import { mapState } from "vuex";
import { VueEditor } from "vue2-editor";
export default {
  data() {
    return {
      contractor: "",

      invoiceNumber: "",
      notice: "",
      date: "",
      overview: "",
      price: "",
      type: "",
    };
  },
  computed: {
    ...mapState(["userProfile", "contractors", "payments"]),
    checkIfExist() {
      return this.payments.filter((item) => {
        if (this.invoiceNumber) {
          if (
            item.invoiceNumber.toUpperCase().includes(this.invoiceNumber.toUpperCase())
          ) {
            return true;
          }
        }
      });
    },
  },
  components: {
    VueEditor,
  },
  methods: {
    addPayment() {
      if (this.contractor) {
        this.$store
          .dispatch("createPayment", {
            contractor: this.contractor,
            invoiceNumber: this.invoiceNumber,
            // amountOfRefrigerant:this.amountOfRefrigerant,
            date: this.date,
            notice: this.notice,
            price: this.price,
            type: this.type,
          })
          .then(
            (this.contractor = ""),
            (this.price = ""),
            (this.invoiceNumber = ""),
            (this.notice = ""),
            (this.date = ""),
            alert("Płatność została dodana")
          );
        router.push("/payments");
      } else {
        alert("brak kontrahenta");
      }
    },
  },
};
</script>
